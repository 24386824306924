/*Limit height of list*/
.str-chat {
  height: unset;
}
/*str-chat__message-text-inner str-chat__message-simple-text-inner*/
.str-chat__channel-preview {
  background: #ffffff;
}
.str-chat__channel-preview--active {
  background: #ffffff;
  color: unset;
  border-left: 3px solid #48D9A4;
}

.str-chat__channel-preview--active .str-chat__channel-preview-title {
	color: unset;
}

.str-chat__channel-preview--active .str-chat__channel-preview-last-message {
	color: unset;
}

.str-chat__channel-preview button:focus {
  background: white;
  box-shadow: inset 0 0 0 1px #48D9A4, inset 0 0 0 2px rgba(0, 108, 255, 0.36);
}

.str-chat__avatar-fallback {
    background-color: #48D9A4;
}

/*ChannelList*/
/*Display list on mobile*/
@media screen and (max-width: 960px) {
  .str-chat-channel-list--open.messaging, .str-chat-channel-list--open.team, .str-chat-channel-list--open.commerce {
    left: initial;
  }
}
/*Display list on mobile*/
@media screen and (max-width: 960px){
  .str-chat-channel-list.messaging, .str-chat-channel-list.team, .str-chat-channel-list.commerce {
    position: initial;
    left: initial;
    top: initial;
    z-index: initial;
    min-height: initial;
    overflow-y: initial;
    box-shadow: initial;
    transition: initial;
  }
}

/* Set channel dimensions*/
.str-chat-channel .str-chat__container {
  height: 100%;
}

@media screen and (max-width: 768px){
  .str-chat-channel .str-chat__container{
    height: 100%;
  }
}

.str-chat.str-chat-channel.messaging {
  height: 100%;
}

@media screen and (max-width: 768px){
  .str-chat__list{
    /*height: calc(100vh -120px);*/
    /*max-height: calc(100% - 120px);*/
  }
  /*.str-chat__input {
    position: fixed;
    width: 100%;
    bottom: 0;
  }*/
  .messaging.str-chat .str-chat__list {
  /*max-height: calc(100% - 60px);*/
    padding-bottom: 120px;
  }

  .mobile-web .messaging.str-chat .str-chat__list {
    /*max-height: calc(100% - 60px);*/
    padding-bottom: 145px;
  }

  .from-ios-app .messaging.str-chat .str-chat__list {
  /*max-height: calc(100% - 60px);*/
    padding-bottom: 175px;
  }

  .str-chat__input-large {
    /*height: 60px;*/
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 1px 0 0 0;
}

/*Hide chat list as hamburger menu inside channel component on mobile*/
.str-chat__header-hamburger {
  display: none;
}

.str-chat__channel-list-team__header {
  display: none
}

/*Fix width of channel list*/
.str-chat-channel-list {
  width: 100%;
}
.str-chat__channel-list-team__main {
  width: 100%;
  background-color: #ffffff;
}
.str-chat__channel-list-team {
  overflow-x: hidden;
}

/* Chat input*/
.str-chat__input {
  /*height: 60px;*/
  padding-bottom: 10px;
}

/* Color of user input on focus*/
.str-chat__textarea textarea:focus {
  background: #fff;
  border: 1px solid #00CF95;
  box-shadow: 0 0 0 2px #00CF95;
}

.str-chat__send-button {
  filter: invert(49%) sepia(67%) saturate(877%) hue-rotate(122deg) brightness(102%) contrast(101%);;
}

/* Background color of traveler messages */
.str-chat__message--me .str-chat__message-text-inner, .str-chat__message-simple--me .str-chat__message-text-inner {
  background-color: #D7F7E9;
}

/* Background color of driver messages */
.str-chat__message-text-inner, .str-chat__message-simple-text-inner{
  background-color: rgba(45,59,78,0.05);
}

/* Hide block with info about online presence below chat input*/
.str-chat__input-footer {
  display: none;
}

/* Hide message actions */
.str-chat__message-simple__actions {
  display: none;
}

/* Hide emoji icon of input*/
.str-chat__emojiselect-wrapper {
  display: none;
}

/* Hide driver's next to message */
.str-chat__message-simple-name {
  display: none;
}

/* Hide traveler's initial image next to his messages*/
.str-chat__message--me > .str-chat__avatar {
  /*visibility: hidden;*/
  display: none;
  margin-bottom: 0px;
}

@media screen and (max-width: 960px){
  .str-chat__message-simple-status {
    bottom: 0px;
    margin-bottom: 0px;
  }

  .str-chat__message--me > .str-chat__avatar {
    visibility: hidden;
    display: unset;
  }
}

/* Move upload image button*/
.str-chat__input .rfu-file-upload-button {
  right: 20px;
}

/* Disable opening attachment images on fullscreen on ios app .from-ios-app*/
.from-ios-app .str-chat__message-simple--me .str-chat__message-attachment--img {
  pointer-events: none;
  touch-action: none;
}