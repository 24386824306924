@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fadeIn {
    animation: spin infinite;
    animation-duration: 2s;
}
