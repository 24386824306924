* {
  --ion-font-family:  'MuseoSans', Helvetica, sans-serif!important;
  font-family:  'MuseoSans', Helvetica, sans-serif !important;
  color: rgb(45, 59, 78);
}

p, span {
  margin: 0;
}

html {
  min-height: 100%;
  height: 1px;
  box-sizing: border-box;
}

button {
  outline: none !important;
}

* body, * main, * #root, .App {
  height: 100%;
  margin: 0px;
  background-color: rgb(244, 245, 246);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  font-kerning: normal;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit !important;
}

.loading-modal {
  --spinner-color: #2d3b4e;
}

.styled-toast {
  --background:  rgb(45, 59, 78);
  color: white;
}
.styled-toast-notify {
  --background:  rgb(45, 59, 78);
  color: white;
  --max-width: 320px;
  --width:100%;
  padding:15px;
  text-align: center;
}

.styled-toast-error {
  --background: #f27470;
  color: white;
  text-align: center;
}

/* Set the icon color and opacity */
ion-select::part(icon) {
  right: 10px;
  opacity: 0.7;
}

/* SPLASH SCREEN */

@-webkit-keyframes fadeOut {
  0% { opacity: 1;}
  99% { opacity: 0.01;width: 100%; height: 100%;}
  100% { opacity: 0;width: 0; height: 0;}
}
@keyframes fadeOut {
  0% { opacity: 1;}
  99% { opacity: 0.01;width: 100%; height: 100%;}
  100% { opacity: 0;width: 0; height: 0;}
}

.splash-container{
  background-color: #00CF95;
  display: block;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  animation-delay: 4s;
}

.transition-right{
  transform: translateX(280%);
  transition:all 2s cubic-bezier(1.000, 0.120, 0.340, 0.855);
}

.transition-left{
  transform: translateX(-280%);
  transition:all 2s cubic-bezier(1.000, 0.120, 0.340, 0.855);
}

/* MUSEO Font */

@font-face {
  font-family: 'MuseoSans';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/MuseoSans_500.ttf');
}

@font-face {
  font-family: 'MuseoSans';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/fonts/MuseoSans_700.ttf');
}

@font-face {
  font-family: 'MuseoSans';
  font-style: italic;
  font-weight: normal;
  src: url('./assets/fonts/MuseoSans_500_Italic.ttf');
}

.otp-input {
  height: 56px !important;
  width: 42px !important;
  font-size: 16px;
  border-radius: 4px;
  color: rgba(45,59,78,0.5);
  border: 1px solid rgba(45,59,78,0.15) !important;
  font-family: inherit;
}
