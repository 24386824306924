@keyframes moveDown
{ from {bottom:0px; opacity: 1;}
to {bottom:-200px; opacity: 0}
}

@-webkit-keyframes moveDown
{
from {bottom:0px; opacity: 1;}
to {bottom:-200px; opacity: 0;}
}

.hide-tabs{
    position:absolute;
    bottom: 0;
    width: 100%;
    animation: moveDown 3s linear;
    animation-iteration-count:1;
    animation-fill-mode: forwards;

/* Safari and Chrome */
-webkit-animation:moveDown 3s forwards;
-webkit-animation-iteration-count:1;
}